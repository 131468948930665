import React, { useEffect, useState } from "react";
import MainLayout from "../nav/MainLayout";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import datajson from "../../test_information.json";
import Select from 'react-select';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import axios from "axios";
import Getusers from "../auth/Getusers";
import ProgressBar from '../reuseable/ProgressBar'

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


const Home = () => {

  const [validated, setValidated] = useState(false);
  const [userType,setuserType] = useState("Existing")
  const [isLoading, setIsloading] = useState(false);
  const [State, setState] = useState("")
  const [Comapny,setCompany]= useState("")

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
  
    setValidated(true);
    setIsloading(true)
    setTimeout(() => {
      setIsloading(false)
    }, 5000);
  };
 

  const Persona = [
    { value: 113, label: 'Aa' },
    { value: 105, label: 'bb' },
  ];

 
  

  return (
    <div id="homePage">
      <Box className="mainLayout" sx={{ display: "flex", overflow: "hidden" }}>
        <MainLayout />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeader />
          <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />
          <div id="mainHome">
            {userType === "New" ? (
              <>
                  New User
              
              </>
            ) : (
              <>
                <div style={{ textAlign: "left", padding: "1rem",display:"grid",gap:"10px" }}>
                  <div
                    style={{
                      display: "grid",
                      justifyContent: "flex-start",
                      textAlign: "left",
                    }}
                  >
                    <span style={{ fontSize: "26px", fontWeight: "600" }}>
                      Welcome Back!!
                    </span>
                    <span style={{ fontSize: "20px",textAlign:"left" }}>
                    Krishan Jhanwar
                    </span>
                  </div>

                  <div>
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <Row className="mb-3">

                      <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom02"
                        >
                          <Form.Label>Select Company Name*</Form.Label>

                          <Form.Select
                            required
                            value={Comapny}
                            onChange={(e) => setCompany(e.target.value)}
                          >
                            <option value="">Select Company</option>
                            <option value="Member">ABC Ltd.</option>
                            <option value="Admin">XYZ Ltd.</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            User Type is required *
                          </Form.Control.Feedback>
                        </Form.Group>
                    
                        {/* <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01Email"
                        >
                          <Form.Label>Email *</Form.Label>
                          <Form.Control
                            className="validationCustom01Email"
                            autoComplete="off"
                            required
                            type="email"
                            placeholder="Email Address"
                            value={EmailAdress}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <Form.Control.Feedback type="invalid">
                            Email Address is required *
                          </Form.Control.Feedback>
                        </Form.Group> */}

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom02"
                        >
                          <Form.Label>Select State *</Form.Label>

                          <Form.Select
                            required
                            value={State}
                            onChange={(e) => setState(e.target.value)}
                          >
                            <option value="">Select State</option>

                            <option value="Andhra Pradesh">Andhra Pradesh</option>
<option value="Arunachal Pradesh">Arunachal Pradesh</option>
<option value="Assam">Assam</option>
<option value="Bihar">Bihar</option>
<option value="Chhattisgarh">Chhattisgarh</option>
<option value="Goa">Goa</option>
<option value="Gujarat">Gujarat</option>
<option value="Haryana">Haryana</option>
<option value="Himachal Pradesh">Himachal Pradesh</option>
<option value="Jharkhand">Jharkhand</option>
<option value="Karnataka">Karnataka</option>
<option value="Kerala">Kerala</option>
<option value="Madhya Pradesh">Madhya Pradesh</option>
<option value="Maharashtra">Maharashtra</option>
<option value="Manipur">Manipur</option>
<option value="Meghalaya">Meghalaya</option>
<option value="Mizoram">Mizoram</option>
<option value="Nagaland">Nagaland</option>
<option value="Odisha">Odisha</option>
<option value="Punjab">Punjab</option>
<option value="Rajasthan">Rajasthan</option>
<option value="Sikkim">Sikkim</option>
<option value="Tamil Nadu">Tamil Nadu</option>
<option value="Telangana">Telangana</option>
<option value="Tripura">Tripura</option>
<option value="Uttar Pradesh">Uttar Pradesh</option>
<option value="Uttarakhand">Uttarakhand</option>
<option value="West Bengal">West Bengal</option>
<option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
<option value="Chandigarh">Chandigarh</option>
<option value="Dadra and Nagar Haveli and Daman and Diu">Dadra and Nagar Haveli and Daman and Diu</option>
<option value="Lakshadweep">Lakshadweep</option>
<option value="Delhi">Delhi</option>
<option value="Puducherry">Puducherry</option>

                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            User Type is required *
                          </Form.Control.Feedback>
                        </Form.Group>

                    
                      </Row>

                      <div
                        style={{
                          gridGap: "10px",
                          display: "flex",
                          marginTop: "1rem",
                        }}
                        className="mb-3"
                      >
                       

                        <Button
                          disabled={isLoading}
                          style={{width:"auto"}}
                          className="submit_btn"
                          type="submit"
                        >
                          {isLoading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span style={{ paddingLeft: "10px" }}>
                              Downloading GST Notices and Orders...
                              </span>
                            </>
                          ) : (
                            "Download GST Notices and Orders"
                          )}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </>
            )}

          </div>
        </Box>
      </Box>
    </div>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";


const Getusers = (props) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
    console.log("User Login Success");		 
      setUserInfo(authState.idToken.claims);
    }
  }, [authState, oktaAuth]);
  
  




  return (
    <> 
      {props?.name && <span style={{ display: "grid", gridGap: "5px",justifyContent:"center",fontWeight:"600" }} className={`userinfo colorCss ${props.className}`}>Krishan Jhanwar</span>}
      {props?.email && <span style={{ display: "grid", gridGap: "5px",justifyContent:"center",fontWeight:"600" }} className={`userinfo colorCss ${props.className}`}>support@datadekho.in</span>}
    </>
  );
}

export default Getusers
